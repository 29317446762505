<template>
<div class="row tgju-widgets-row"> 
    <slot v-if="widgetLoad">
        <div class="text-center p-2 w100-w">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>

        <div class="market-profile-inner">
         
            <ul class="m-pointer">
                <li class="li-head" style="border-top: 0;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">عنوان</div>
                        <div class="profile-inner-table-value">مقدار</div>
                        <div class="profile-inner-table-actions actions-min-w"></div>
                    </div>
                </li>

                <li :class="activeLi == 100 || activeLi == 0 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(100)">
                        <div class="profile-inner-table-name">‌میانگین متحرک (EMA) {{ market_indicator.info.title }}</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 5 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.ema[5]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 10 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.ema[10]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 20 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.ema[20]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 30 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.ema[30]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 50 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.ema[50]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 200 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.ema[200]) }}</div>
                            </div>
                        </div> 

                    </div>
                </li>
                <li :class="activeLi == 101 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(101)">
                        <div class="profile-inner-table-name">‌میانگین متحرک (SMA) {{ market_indicator.info.title }}</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 5 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.sma[5]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 10 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.sma[10]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 20 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.sma[20]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 30 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.sma[30]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 50 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.sma[50]) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی 200 روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.indicator_values.sma[200]) }}</div>
                            </div>
                        </div> 

                    </div>
                </li>
                <li class="li-head" style="border-top: 0;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name"> سطوح ‌حمایت و مقاومت {{ market_indicator.info.title }}</div>
                    </div>
                </li>

                <li :class="activeLi == 103 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(103)">
                        <div class="profile-inner-table-name">سطح حمایت 3 (S3)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.s3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.s3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.s3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.s3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>

                <li :class="activeLi == 104 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(104)">
                        <div class="profile-inner-table-name">سطح حمایت 2 (S2)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.s2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.s2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.s2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.s2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>
    
                <li :class="activeLi == 105 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(105)">
                        <div class="profile-inner-table-name">سطح حمایت 1 (S1)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.s1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.s1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.s1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.s1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>

                <li :class="activeLi == 106 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(106)">
                        <div class="profile-inner-table-name">نقطه پیوت (PP)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.pp) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.pp) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.pp) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.pp) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.demark.pp) }}</div>
                            </div>
                        </div> 
                    </div>
                </li>

                <li :class="activeLi == 107 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(107)">
                        <div class="profile-inner-table-name">مقاومت 1 (R1)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.r1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.r1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.r1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.r1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.demark.r1) }}</div>
                            </div>
                        </div> 
                    </div>
                </li>

                <li :class="activeLi == 108 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(108)">
                        <div class="profile-inner-table-name">مقاومت 2 (R2)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.r2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.r2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.r2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.r2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>

                <li :class="activeLi == 109 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(109)">
                        <div class="profile-inner-table-name">مقاومت 3 (R3)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.r3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.r3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.r3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.r3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>


            </ul>

        </div>
        



        <!-- 
            <div class="tgju-widgets-block col-12 col-md-12 col-lg-6 profile-indicator">
                <div class="tgju-widget light has-icon">
                    <h2 class="tgju-widget-title dot m-0">
                        ‌میانگین متحرک (EMA) {{ market_indicator.info.title }}
                    </h2>
                    <div class="tables-data tables-border-bt-none table-mobile-grid">
                        <table class="table">
                            <thead class="text-center">
                                <tr>
                                    <th>اندیکاتور</th>
                                    <th>بازه زمانی</th>
                                    <th>مقدار</th>
                                </tr>
                            </thead>
                            <tbody class="table-padding-lg">
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                    <td>5 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.ema[5]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                    <td>10 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.ema[10]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                    <td>20 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.ema[20]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                    <td>30 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.ema[30]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                    <td>50 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.ema[50]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                    <td>100 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.ema[100]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                    <td>200 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.ema[200]) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="tgju-widgets-block col-12 col-md-12 col-lg-6 profile-indicator" data-target="profile-tour-step-15">
                <div class="tgju-widget light has-icon">
                    <h2 class="tgju-widget-title dot m-0">
                    ‌میانگین متحرک (SMA) {{ market_indicator.info.title }}
                    </h2>
                    <div class="tables-data tables-border-bt-none table-mobile-grid">
                        <table class="table">
                            <thead class="text-center">
                                <tr>
                                    <th>اندیکاتور</th>
                                    <th>بازه زمانی</th>
                                    <th>مقدار</th>
                                </tr>
                            </thead>
                            <tbody class="table-padding-lg">
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                    <td>5 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.sma[5]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                    <td>10 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.sma[10]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                    <td>20 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.sma[20]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                    <td>30 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.sma[30]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                    <td>50 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.sma[50]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                    <td>100 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.sma[100]) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                    <td>200 روز</td>
                                    <td>{{ formatPrice(market_indicator.indicators.indicator_values.sma[200]) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="tgju-widgets-block col-12 col-md-12 col-lg-12 profile-indicator-resistance" >
                <div class="tgju-widget light has-icon">
                    <h2 class="tgju-widget-title dot m-0">
                        سطوح ‌حمایت و مقاومت {{ market_indicator.info.title }}
                    </h2>
                    <div class="tables-data tables-border-bt-none table-mobile-grid">
                        <table class="table dataTable">
                            <thead class="text-center">
                                <tr>
                                    <th>سطوح</th>
                                    <th>کلاسیک (Classic)</th>
                                    <th>فیبوناچی (Fibonacci)</th>
                                    <th>کاماریلا (Camarilla)</th>
                                    <th>وودی (Woodie)</th>
                                    <th>دی مارک (DM)</th>
                                </tr>
                            </thead>
                            <tbody class="table-padding-lg">
                                <tr>
                                    <td class="text-tbl-first">حمایت 3 (S3)</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.s3) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.s3) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.s3) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.s3) }}</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">حمایت 2 (S2)</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.s2) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.s2) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.s2) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.s2) }}</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">حمایت 1 (S1)</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.s1) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.s1) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.s1) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.s1) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.demark.s1) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">نقطه پیوت (PP)</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.pp) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.pp) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.pp) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.pp) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.demark.pp) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">مقاومت 1 (R1)</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.r1) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.r1) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.r1) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.r1) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.demark.r1) }}</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">مقاومت 2 (R2)</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.r2) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.r2) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.r2) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.r2) }}</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td class="text-tbl-first">مقاومت 3 (R3)</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.classic.r3) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.fibonacci.r3) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.camarilla.r3) }}</td>
                                    <td>{{ formatPrice(market_indicator.indicators.support_and_resistance.woodie.r3) }}</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> 
        -->
    </slot>
</div>
</template>

<script>
export default {
    name: "MarketIndicatorComponent",
    data() {
        return {
            widgetLoad: true,
            market_indicator: [],
            activeLi:0,

        }
    },
    mounted() {
        this.getProfileIndicator();
    },
    methods: {
        
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }

        },
        getProfileIndicator() {
            this.$helpers
                .makeRequest("GET", `/market/profile-indicator/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_indicator = api_response.data.response.detail.response;
                        this.$parent.title = this.market_indicator.info.title + ' - ' + this.$route.meta.title;
                        this.widgetLoad = false;
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
}
</script>