<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12" style="padding: 0 !important;">
        <MarketHeader :data="$route.params.name"></MarketHeader>
        <!-- {{ title }} -->
        <!-- <div class="market-main-sub-tabs w-scroll-style">
            <div v-for="(item, index) in marketNavPage" :key="index" v-if="!item.hide" :class="'market-main-sub-tab-item ' + (item.name == tabName ? 'active' : '') ">
                 <router-link :to="{name: item.name, params: { name: $route.params.name }, }">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </div> -->
    </div>
    <MarketIndicator></MarketIndicator>
</div>
</template>
<style>

    @media (max-width: 1220px) {

        .profile-indicator .tgju-widget .table-mobile-grid tbody tr td:nth-child(1)::before {
            content: 'اندیکاتور :  ' !important;
        }

        .profile-indicator .tgju-widget  .table-mobile-grid tbody tr td:nth-child(2)::before {
            content: 'دوره زمانی اعمال شده' !important;
        }

        .profile-indicator .tgju-widget  .table-mobile-grid tbody tr td:nth-child(3)::before {
            content: 'مقدار' !important;
        }

        .profile-indicator-resistance .tgju-widget .table-mobile-grid tbody tr td:nth-child(1)::before {
            content: 'سطح :  ' !important;
        }	

        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(2)::before{
            content: 'کلاسیک (Classic)' !important;
        }

        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(3)::before{
            content: 'فیبوناچی (Fibonacci)' !important;
        }

        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(4)::before{
            content: 'کاماریلا (Camarilla)' !important;
        }

        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(5)::before{
            content: 'وودی (Woodie)' !important;
        }
        
        .profile-indicator-resistance .tgju-widget .table-mobile-grid.tables-data tbody tr td:nth-child(6)::before{
            content: 'دی مارک (DM)' !important;
        }
    }
    
    .technical-analysis-box {
        position:relative
    }
    .technical-analysis-title {
        position: absolute;
        background: #fff;
        text-align: center;
        width: 100%;
        font-size: 20px;
        color: #333;
        padding: 25px 20px;
        font-weight: 600;
    }
    .technical-analysis-cp-hide {
        position:absolute;
        right:0;
        width:100px;
        background:#fff;
        height:45px;
        bottom:0
    }

    html.dark .technical-analysis-title {
        background:#333;
        color: #fff;
    }
    html.dark .technical-analysis-cp-hide {
        background:#333;
    }
    @media (min-width: 992px) {
        .p-min-technical-analysis{
            padding-top: 60px;
        }
    } 
</style>

<script>
// این ویو برای ماژول  بازار  پروفایل شاخص ، بخش اولیه مورد استفاده قرار میگیرد
import MarketIndicator from "@/components/Mobile/Market/Profile/Indicator";
import marketNavPage from "@/assets/static_market_nav_page";
import MarketHeader from '@/components/Mobile/Market/Profile/Header';

export default {
    name: "marketIndicator",
    components: {
        MarketIndicator,
        MarketHeader,
    },
    data: function () {
        return {
            title: "--",
            symbol: "--",
            marketNavPage,
            tabName:'MarketIndicator',
        };
    },
    mounted() {
    },
};
</script>
